<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "app",
  created() {
    // var sUserAgent = navigator.userAgent.toLowerCase();
    var isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(
      navigator.userAgent
    );
    if (isMobile) {
      this.setHtmlSizeMobile();
      // window.location.href = "https://ch.m.atoshi.org/#/";
    } else {
      this.setHtmlSizePc();
    }

    if (Reflect.has(window, 'getClientLanguage')) { // 根据app语言来切换语言
      getClientLanguage.postMessage('')
    }

  },
  mounted(){
    window.clientLanguageEnglish = this.clientLanguageEnglish
  },
  methods: {

    clientLanguageEnglish(isEn) { // 客户端返回当前语言
      console.log(isEn);
      let value = isEn ? 'en' : 'ch';
      this.$i18n.locale = value;
      sessionStorage.setItem("isEn", isEn); // 修改会话缓存
      this.$store.commit('setIsEn', isEn); // 修改全局状态
    },

    loadYoutubeJs() {
      $.ajax({
        type: "GET",
        url: "https://aggshop.juhaowu.cn/intbaseapi/base/ipFrom",
        contentType: "application/json;charset=utf-8",
        data: {},
        headers: { language: "chinese" },
        success: function (res) {
          if (res.data) {
            if (res.data.isChina) {
            } else {
              let tag = document.createElement("script");
              tag.src = "https://www.youtube.com/iframe_api";
              let firstScriptTag = document.getElementsByTagName("script")[0];
              firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            }
          }
        },
        fail: function (error) {},
      });
    },

    setHtmlSizeMobile() {
      //获取页面宽度
      var winW = document.body.clientWidth;
      //根据页面宽度设置html字体大小。
      document.documentElement.style.fontSize = winW / (750 / 100) + "px";
      //当页面大小发生改变,重新修正rem为新窗口尺寸的3.75分之一
      window.onresize = function () {
        document.documentElement.style.fontSize =
          document.body.clientWidth / (750 / 100) + "px";
      };
    },
    setHtmlSizePc() {
      //获取页面宽度
      var winW = document.body.clientWidth;
      //根据页面宽度设置html字体大小。
      document.documentElement.style.fontSize = winW / (1920 / 20) + "px";
      //当页面大小发生改变,重新修正rem为新窗口尺寸的3.75分之一
      window.onresize = function () {
        document.documentElement.style.fontSize =
          document.body.clientWidth / (1920 / 20) + "px";
      };
    },
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

router-view {
  width: 100%;
  height: 100%;
  min-height: 100%;
}
</style>
