import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './common/css/common.css';
import { langSet, getQueryVariable, isEn, handleSysLang } from '@/utils'

import Vant ,{Lazyload, SwipeItem, Popup, Toast } from 'vant';
import 'vant/lib/index.css';

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

Vue.prototype.$axios = axios
Vue.use(Vant);
Vue.use(Lazyload);
Vue.use(Popup);
Vue.use(SwipeItem);

Vue.config.productionTip = false



// 国际化
const messages = {
  'ch': require('@/common/lang/ch.js'),
  'en': require('@/common/lang/en.js')
}

// let locale = isEn() ? "en" : "ch";
let locale = handleSysLang() === 'en' ? "en" : "ch";
const i18n = new VueI18n({
  // locale: cookie.get('lang') || 'cn',
  locale,
  messages,
  silentTranslationWarn: true
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
