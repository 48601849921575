import Vue from 'vue'
import Vuex from 'vuex'
import { isEn, handleSysLang } from '@/utils'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    isEn: handleSysLang()
  },
  mutations: {
    setIsEn(state, isEn) {
      state.isEn = isEn
    }
  },
  actions: {
  },
  modules: {
  }
})
