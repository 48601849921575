export function langSet() {
  let locale = ((document.referrer.indexOf('detok') > -1) || (document.referrer.indexOf('facebook') > -1) || (document.referrer.indexOf('twitter') > -1) || document.URL.indexOf('detok') > -1) ? 'en' : 'ch';
  return locale;
}

// 判断系统语言
export function handleSysLang() {
  let lang = window.navigator.language;
  if (lang == 'zh-CN') {
    return 'zh-CN';
  } else if (lang == 'zh-TW') {
    return 'zh-TW';
  } else {
    return 'en';
  }
}

export function getQueryVariable(variable) {
  let url = window.location.href;
  var query = url.indexOf('?') > -1 ? url.split("?")[1] : ''; // 根据“?”切割数组，截取“?”之后的字符串
  // ['https://qq.com','appid=1234567890&name=Zdf']
  var vars = query.split("&"); // 根据“&”切割成数组
  // ['appid=1234567890','name=Zdf']
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    // ['appid','1234567890']
    if (pair[1] === 'zh-CN') {
      pair[1] ='ch';
    }
    if (pair[0] == variable) return pair[1]; // 没有花括号默认执行第一个js语句
  }
  return (false);
}

// 判断是否为英文
export function isEn() {
  return JSON.parse(sessionStorage.getItem("isEn"))
}