import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: "/",
    component: resolve => require(['@/pages/DeTok.vue'], resolve),
    meta: {
        title: "DeTok"
    }
  },
  // 邀请注册
  {
    path: '/inviteDeTok',
    component: resolve => require(['@/pages/register/index.vue'], resolve),
    meta: {
        title: ""
    }
  },
  {
    path: '/registerDeTok',
    component: resolve => require(['@/pages/register/register.vue'], resolve),
    meta: {
        title: ""
    }
  },
  // 视频
  {
    path: '/videoList',
    name: 'videoList',
    component: resolve => require(["@/pages/video/list"], resolve),
  },
  {
    path: '/youtubeplayers',
    name: 'youtubeplayers',
    component: resolve => require(["@/pages/video/player"], resolve),
  },
  // 新闻
  {
    path: '/newsDetails',
    name: 'newsDetails',
    component: resolve => require(["@/pages/newsDetails"], resolve),
  },
  // 照片
  {
    path: '/picList',
    name: 'picList',
    component: resolve => require(["@/pages/picture/list"], resolve),
  },
  {
    path: '/picDetails',
    name: 'picDetails',
    component: resolve => require(["@/pages/picture/details"], resolve),
  },
  // 音频
  {
    path: '/audioList',
    name: 'audioList',
    component: resolve => require(["@/pages/audio/list"], resolve),
  },
  {
    path: '/audioDetail',
    name: 'audioDetail',
    component: resolve => require(["@/pages/audio/details"], resolve),
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
